/* eslint-disable react/no-array-index-key */
import React from "react"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const BasicIFrame = ({ section, index, ...props }: SectionProps) => {
  
  return (
    <div className={`relative ${section.advanced?.class || ``}`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0">
        {section.blurb && <div className="pb-6" dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
        <div className="">
          <div className="relative" style={{ paddingTop: `56.25%` }}>
            <iframe
              className="absolute inset-0 w-full h-full findme-temp"
              src={section.iframe.url}
              title={section.iframe.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicIFrame
